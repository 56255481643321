import React, { useEffect, useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import Modal from '../Modal';
import avatar from '../../assets/avatar_robot.svg';
import book from '../../assets/bookmarked_book.svg';

const ragInfo = {
    "method": "kb",
    "identifier": "29R7R6RVRB",
    "template": ""
};

const reportTypes = [
  {
    id: 'general_store_layout',
    name: 'General Store Layout Operations',
    description: 'General store layout principles and operational information for concept stores.',
    meta_data_filter_config: {
      "andAll": [
          {
              "equals": {
                  "key": "CAT_store_layout",
                  "value": "true"
              }
          }
      ]
    },
    subTypes: [
      {
        id: 'general_store_layout_1',
        type: "How-to-guide",
        prompt: "What basic elements should every Stockroom should contain and the three product flow standards? Outline all of the flows in detail.",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_stockroom",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'general_store_layout_2',
        type: "Commonly Asked Question",
        prompt: "What are the lacing standards?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_lacing",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'general_store_layout_3',
        type: "Commonly Asked Question",
        prompt: "What are the best practices for maintaining an organized and appealing sales floor on a daily basis?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_maintain_salesfloor",
                      "value": "true"
                  }
              }
          ]
        },
      },
    ],
  },
  {
    id: 'point_of_sale',
    name: 'Point of Sale (POS) Operations',
    description: 'Essential information on all aspects of point of sale operations for all stores.',
    meta_data_filter_config: {
      "andAll": [
          {
              "equals": {
                  "key": "CAT_pos",
                  "value": "true"
              }
          }
      ]
    },
    subTypes: [
      {
        id: 'point_of_sale_1',
        type: "How-to-guide",
        prompt: "Walk me through the steps for issuing a gift card.",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_issue_gift_card",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'point_of_sale_2',
        type: "Commonly Asked Question",
        prompt: "How do I apply a discount or promotion to a transaction?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_apply_discount",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'point_of_sale_3',
        type: "Commonly Asked Question",
        prompt: "How do I process a return or refund?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_process_return",
                      "value": "true"
                  }
              }
          ]
        },
      },
    ],
  },
  {
    id: 'marketing',
    name: 'Marketing Operations',
    description: 'Insights into marketing strategies and operations for big box and outlet stores.',
    meta_data_filter_config: {
      "andAll": [
          {
              "equals": {
                  "key": "CAT_marketing",
                  "value": "true"
              }
          }
      ]
    },
    subTypes: [
      {
        id: 'marketing_1',
        type: "Commonly Asked Question",
        prompt: "What is the guidance for how to use a bin topper, specifically how many and where?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_bin_topper",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'marketing_2',
        type: "Commonly Asked Question",
        prompt: "How do I place price tags on socks?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_sock_price_tag",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'marketing_3',
        type: "Commonly Asked Question",
        prompt: "How do I handle curb signs? ",
        meta_data_filter_config: {
          "andAll": [
            {
              "equals": {
                  "key": "Q_curb_signs",
                  "value": "true"
              }
            }
          ]
        },
      },
    ],
  },
  {
    id: 'apparel_mannequin_standards',
    name: 'Apparel / Mannequin Standards',
    description: 'Knowledge about display and presentation standards for apparel and mannequins for all stores.',
    meta_data_filter_config: {
      "andAll": [
          {
              "equals": {
                  "key": "CAT_apparel_mannequin",
                  "value": "true"
              }
          }
      ]
    },
    subTypes: [
      {
        id: 'apparel_mannequin_standards_1',
        type: "Commonly Asked Question",
        button_text: "How do I prepare the apparel before dressing the mannequins?",
        prompt: "How do I prepare the apparel before dressing the mannequins (give me all of the steps and only focus on mannequin standards)?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_prepare_apparel",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'apparel_mannequin_standards_2',
        type: "Commonly Asked Question",
        prompt: "What is the best way to fold and restock apparel on shelves and tables?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_fold_restock",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'apparel_mannequin_standards_3',
        type: "Commonly Asked Question",
        prompt: "How do I organize clearance apparel?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_clearance_apparel",
                      "value": "true"
                  }
              }
          ]
        },
      },
    ],
  },
  {
    id: 'larger_retail_store_layout',
    name: 'Larger Retail Store Layout Operations',
    description: 'Information regarding layout strategy and operations for larger retail establishments.',
    meta_data_filter_config: {
      "andAll": [
          {
              "equals": {
                  "key": "CAT_store_layout_large",
                  "value": "true"
              }
          }
      ]
    },
    subTypes: [
      {
        id: 'larger_retail_store_layout_1',
        type: "Commonly Asked Question",
        prompt: "How can I enhance visibility and signage in a large store?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_signage_visibility",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'larger_retail_store_layout_2',
        type: "Commonly Asked Question",
        button_text: "How do I organize clearance items at large stores?",
        prompt: "How do I organize clearance items at large stores? Focus on clearance section and clearance section walls.",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_clearance_discount_items",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'larger_retail_store_layout_3',
        type: "Commonly Asked Question",
        prompt: "How do I handle frequent inventory changes and new arrivals at outlet stores?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_inventory_changes_arrivals",
                      "value": "true"
                  }
              }
          ]
        },
      },
    ],
  },
  {
    id: 'supply_ordering',
    name: 'General Store Layout Operations',
    description: 'Comprehensive information about supply ordering processes and operations for all stores.',
    meta_data_filter_config: {
      "andAll": [
          {
              "equals": {
                  "key": "CAT_supply_ordering",
                  "value": "true"
              }
          }
      ]
    },
    subTypes: [
      {
        id: 'supply_ordering_1',
        type: "How-to-guide",
        prompt: "How do I place an order?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_place_order",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'supply_ordering_2',
        type: "Commonly Asked Question",
        prompt: "How do I follow-up on an order? ",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_followup_order",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'supply_ordering_3',
        type: "How-to-guide",
        prompt: "How do I check the status of an order?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_order_status",
                      "value": "true"
                  }
              }
          ]
        },
      },
    ],
  },
  {
    id: 'mobile_pos',
    name: 'Mobile POS',
    description: 'Core knowledge on the operation of mobile point of sale systems across all stores.',
    meta_data_filter_config: {
      "andAll": [
          {
              "equals": {
                  "key": "CAT_pos_mobile",
                  "value": "true"
              }
          }
      ]
    },
    subTypes: [
      {
        id: 'mobile_pos_1',
        type: "Commonly Asked Question",
        prompt: "How do I void an item on the mobile POS system?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_void_item_pos_mobile",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'mobile_pos_2',
        type: "Commonly Asked Question",
        prompt: "How do I manually discount an item on the mobile POS system?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_discount_item_pos_mobile",
                      "value": "true"
                  }
              }
          ]
        },
      },
      {
        id: 'mobile_pos_3',
        type: "Commonly Asked Question",
        prompt: "How to a process a customer paying in cash on the mobile POS system?",
        meta_data_filter_config: {
          "andAll": [
              {
                  "equals": {
                      "key": "Q_cash_payment_pos_mobile",
                      "value": "true"
                  }
              }
          ]
        },
      },
    ],
  },
];

/**
 * A component that displays an image.
 *
 * @param {string} text - The source of the image to display.
 * @returns {JSX.Element} - A JSX element representing the image.
 */
const OperationalKnowledgeBaseSelection = (props) => {

  // const navigate = useNavigate();
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [selectedReportSubType, setSelectedReportSubType] = useState(null);
  const [selectionsVisible, setSelectionsVisible] = useState(true);

  // const [modalOpen, setModalOpen] = useState(true);
  // const params = useParams();

  // selecting a sub-report first needs to update the ragInfo on the parent component (ChatView)
  // which will then trigger sending the prompt after the updated ragInfo is set
  useEffect(() => {
    const mergedRagInfo = { ...ragInfo, meta_data_filter_config: selectedReportType?.meta_data_filter_config }
    props.setRagInfo(mergedRagInfo);
    console.log('set rag info', mergedRagInfo);
  }, [selectedReportType]);

  useEffect(() => {
    const mergedRagInfo = { ...ragInfo, meta_data_filter_config: selectedReportSubType?.meta_data_filter_config }
    props.setRagInfo(mergedRagInfo);
    console.log('set rag info', mergedRagInfo);
  }, [selectedReportSubType]);

  useEffect(() => {
    if(props.ragInfo && selectedReportSubType?.prompt) {
      console.log('get rag info', props.ragInfo);
      console.log('send prompt')
      props.submitPrompt(null, selectedReportSubType.prompt);
      setSelectionsVisible(false);
    }
  }, [props.ragInfo]);

  const backToSelection = () => {
    
    console.log("back");
    if(!selectionsVisible && selectedReportSubType) {
      setSelectedReportSubType(null);
      setSelectedReportType(null);
      setSelectionsVisible(true);
    } else if (!selectedReportType) {
      setSelectionsVisible(!selectionsVisible)
    } else {
      setSelectedReportType(null);
      setSelectedReportSubType(null);
      setSelectionsVisible(true);
    }
  }

  return (
    <div className={`flex flex-col bg-white xxitems-center xxjustify-center grow absolute top-0 left-0 right-0 z-10`}>
      {/* <Modal title="" modalOpen={modalOpen} useClose={false} classes={'use-case-params-modal'}> */}
        <div style={{"height": selectionsVisible ? "calc(100% - 160px)" : "auto", "overflow-y": "auto"}}>
          <div style={{padding: "2em"}} className="bg-white rounded-xl container mx-auto">

            <div 
              style={{paddingTop: 8, paddingBottom: 8, color: "#4087C8", borderColor: "#D1E9FF", borderWidth: 1, width: 180, textAlign: "center"}} 
              className={`rounded-lg cursor-pointer hover:bg-blue-50 transition-colors duration-200 ${!selectionsVisible || selectedReportType?.subTypes ? "" : "hidden"}`}
              onClick={() => backToSelection()}
            >
              <span style={{fontWeight: "bold"}}>{"<"}</span> Back to All Topics
            </div>

            {/* Main Header */}
            <div className={selectedReportType?.subTypes ? "hidden" : ""} style={{ marginTop: 40, marginBottom: 40, maxWidth: "54rem", marginLeft: "auto", marginRight: "auto"}}>
              <div style={{ float: "left", marginRight: 30 }} ><img src={avatar} alt="AI Assisstant Avatar Icon" /></div>
              <p className="text-gray-400 text-lg">
                <span className="font-bold text-3xl text-gray-900">Welcome to Skecher's In-Store AI Assistant.</span>
              </p>
              <p className="text-gray-500 text-lg mt-4">
                Click on any category below for quick access to commonly asked questions or type in your own queries. Whether you're seeking detailed procedures, best practices or quick tips, SkechAI will help you find the information you need.
              </p>
            </div>

            {/* Category Header */}
            <div className={!selectedReportSubType && selectedReportType?.subTypes ? "" : "hidden"} style={{ marginTop: 40, marginBottom: 40, maxWidth: "48rem", marginLeft: "auto", marginRight: "auto"}}>
              <div style={{ float: "left", marginRight: 30 }} ><img src={book} alt="AI Assisstant Avatar Icon" /></div>
              <p className="text-gray-400 text-lg">
                <span className="font-bold text-xl text-gray-900">{selectedReportType ? selectedReportType.name : ""}</span>
              </p>
              <p className="text-gray-500 text-lg mt-4">
                Here are some common topics and frequently asked questions about {selectedReportType ? selectedReportType.name : ""}.
              </p>
            </div>

            {/* Categories */}
            <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-4xl mx-auto ${selectedReportType?.subTypes ? "hidden" : ""}`}>
              {reportTypes.map((reportType) => (
                <div key={reportType.id} style={{padding: 10}} className={`aspect-square bg-white rounded-lg border-2 border-blue-200 cursor-pointer hover:bg-blue-50 transition-colors duration-200`} onClick={() => {setSelectedReportType(reportType); setSelectedReportSubType(null);}}>
                  <div className="font-bold">
                    <img width="60" style={{float: "left", marginRight: 10}} src={book} alt="Book Icon" />{reportType.name}
                    {reportType.name.length <= 10 ? (
                      <><br /><br /><br /></>
                    ) : (
                      reportType.name.length <= 20 ? <><br /><br /></> : null
                    )}
                  </div>
                  <div style={{color: "grey"}} className="mt-3 text-sm">
                    {reportType.description}
                  </div>
                </div>
              ))}
            </div>

            {/* Category Questions */}
            <div className={`grid grid-cols-1 gap-4 max-w-3xl mx-auto ${!selectedReportSubType && selectedReportType?.subTypes ? "" : "hidden"}`}>
              {selectedReportType?.subTypes?.map((reportType) => (
                <div key={reportType.id} style={{padding: 20}} className={`bg-white rounded-lg border-2 border-blue-200 cursor-pointer hover:bg-blue-50 transition-colors duration-200`} onClick={() => {setSelectedReportSubType(reportType)}}>
                  <div style={{color: "#aaa"}} className="text-sm">
                    {reportType.type}
                  </div>
                  <div style={{color: "black", fontWeight: "bold"}} className="mt-3 text-lg">
                    {reportType.prompt}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      {/* </Modal> */}
    </div>
  )
};

export default OperationalKnowledgeBaseSelection;
export { reportTypes };

