import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { RequiredAuth } from './SecureRoute';

import { DisclaimerContextProvider } from '../context/disclaimerContext';
import { SessionContextProvider } from '../context/sessionContext';
import { ChatContextProvider } from '../context/chatContext';
import { TextConfigContextProvider } from '../context/textConfigContext';
import { ImageConfigContextProvider } from '../context/imageConfigContext';

import Loading from './Loading';
import Login from './Login';
import Landing from './Landing/Landing';
import LearnMore from './LearnMore/LearnMore';
import TextChat from './TextChat/TextChat';
import ImageChat from './ImageChat/ImageChat';
import UseCaseLibrary from './UseCaseLibrary/UseCaseLibrary';
import ProductKnowledgeBase from "./UseCaseLibrary/ProductKnowledgeBase"
import ChatHistory from './ChatHistory/ChatHistory';
import Help from './Help/Help';
import UsageStats from './UsageStats';
import AnnouncementList from './AnnouncementList';
import SavedPromptsMobile from './Common/Mobile/SavedPromptsMobile';

const AppRoutes = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div>
      {process.env.REACT_APP_AUTH_METHOD?.toLowerCase() !== 'okta' && !isAuthenticated ? (
        <Login onLogin={setIsAuthenticated} />
      ) : (
        <DisclaimerContextProvider>
        <SessionContextProvider>
        <ChatContextProvider>
        <TextConfigContextProvider>
        <ImageConfigContextProvider>

          <Routes>
            <Route path="login/callback" element={<LoginCallback loadingElement={<Loading/>}/>}/>

            <Route path="/" element={process.env.REACT_APP_AUTH_METHOD?.toLowerCase !== 'okta' ? <RequiredAuth/> : <div />}>

              <Route path="/" element={<Landing />} />
              <Route path="/learn" element={<LearnMore />} />

              <Route path="/text" element={<TextChat />} />
              <Route path="/text/history" element={<ChatHistory modelType="text"/>} />
              <Route path="/text/usecases" element={<UseCaseLibrary modelType="text"/>} />
              <Route path="/text/help" element={<Help modelType="text"/>} />
              <Route path="/text/usecase/:useCaseId" element={<TextChat />} />
              <Route path="/text/usecase/poductkb" element={<ProductKnowledgeBase />} />
              <Route path="/text/usecase/:useCaseId/:sessionId" element={<TextChat />} />
              <Route path="/text/:sessionId" element={<TextChat />} />
              <Route path="/text/prompts" element={<SavedPromptsMobile modelType="text" />} />

              <Route path="/image" element={<ImageChat />} />
              <Route path="/image/history" element={<ChatHistory modelType="image"/>} />
              <Route path="/image/usecases" element={<UseCaseLibrary modelType="image"/>} />
              <Route path="/image/help" element={<Help modelType="image"/>} />
              <Route path="/image/usecase/:useCaseId" element={<ImageChat />} />
              <Route path="/image/usecase/:useCaseId/:sessionId" element={<ImageChat />} />
              <Route path="/image/:sessionId" element={<ImageChat />} />
              <Route path="/image/prompts" element={<SavedPromptsMobile modelType="image" />} />

              <Route path="/stats" element={<UsageStats />} />

              {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
              <Route path="*" element={<Landing />} />
              <Route path="/announcements" element={<AnnouncementList />} />

            </Route>

          </Routes>
        </ImageConfigContextProvider>
        </TextConfigContextProvider>
        </ChatContextProvider>
        </SessionContextProvider>
        </DisclaimerContextProvider>
      )}
    </div>
  );
};

export default AppRoutes;
