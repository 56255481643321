import { useState } from 'react'

/**
 * A custom hook for managing the conversation between the user and the AI.
 *
 * @returns {Object} An object containing the `messages` array and the `addMessage` function.
 */
const useMessageCollection = () => {
  const [messages, setMessages] = useState([]);

  /**
  * A function for adding a new message to the collection.
  *
  * @param {Object} message - The message to add to the collection.
  */
  const addMessage = (message) => {
    setMessages((prev) => [...prev, message]);
  }

  /**
   * A function to stream text to the last created message.
   * @param {string} message 
   */
  const streamMessage = (message) => {
    setMessages((prev) => {
      const updated = [...prev];
      updated[updated.length - 1].text = message;
      // update the id property to force a re-render on current message, 
      // otherwise messages are memo-ized to avoid re-rendering

      // avoid creating NaN id's, because will end up with duplicate keys, which may cause duplicates to be removed & disappear
      // ID's are either a simple integer, or can be a GUID with a number appended to it, eg; b5056f67-f50d-4cae-be60-7e7b4621c4e1-1730418811.504546
      const priorId = updated[updated.length - 1].id;
      let newId;

      if(isNaN(priorId) && priorId.indexOf(".") > 0) {
        const parts = priorId.split(".");
        parts[1] = (parseInt(parts[1]) + 1).toString();
        newId = parts.join(".");
      } else {
        newId = priorId + 1;
      }

      // double check..
      if(newId.toString() === "NaN") {
        newId = (Date.now() + Math.floor(Math.random() * 1000000))
      }

      updated[updated.length - 1].id = newId;
      return updated;
    })
  }


  const setStreamedMessageStopInfo = (id, inputTokens = 0, outputTokens = 0) => {
    setMessages((prev) => {
      const updated = [...prev];
      const lastMessage = updated[updated.length - 1];

      lastMessage.id = id;
      lastMessage.input_tokens = inputTokens;
      lastMessage.output_tokens = outputTokens;
      lastMessage.total_tokens = inputTokens + outputTokens;
      return updated;
    });
  };

  const clearMessages = (modelType, sessionId) => {
    if(modelType || sessionId) {
      const updatedMessages = messages.filter((msg) => !(msg.modelType===modelType || msg.sessionId === sessionId));
      setMessages(updatedMessages);
    } else {
      setMessages([])
    }
  }

  return [messages, addMessage, streamMessage, clearMessages, setStreamedMessageStopInfo];
}

export default useMessageCollection