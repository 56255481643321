import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../Modal';
import DatePicker from "react-datepicker";
import { MdCalendarMonth, MdOutlineSettings } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";

const reportTypes = [
  {
    id: 'kings_report_top_volume_skus',
    name: 'Top Volume SKUs',
    description: '',
    queryKey: 'top_vol_skus',
    apiParams: {
      use_case: 'kings_report',
      query_name: 'top_vol_skus',
      summarize_result: false,
      // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
    },
    display: {
      // options: {
      //   columns: [
      //     "Root Cause", { name: "Frequency", align: "right" }, "Preventative Actions"
      //   ],
      // },
      transform: (data) => {
        return data.map((item) => {
          delete item.values_by_date;
          return item;
        });
      }
    },
  },
  {
    id: 'kings_report_order_support',
    name: 'Low 5-week Inventory',
    description: '',
    queryKey: 'order_support',
    display: {
      // options: {
      //   columns: [
      //     "Root Cause", { name: "Frequency", align: "right" }, "Preventative Actions"
      //   ],
      // },
      transform: (data) => {
        return data.map((item) => {
          item.latestDate = new Date(item.latestDate).toLocaleDateString();
          return item;
        });
      }
    },
    apiParams: {
      use_case: 'kings_report',
      query_name: 'order_support',
      summarize_result: false,
      // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
    },
  },
  {
    id: 'kings_report_best_sell_through',
    name: 'Best Sell Through',
    description: 'Markdown < 40% and sold over 30 pairs',
    queryKey: 'best_sell_through',
    apiParams: {
      use_case: 'kings_report',
      query_name: 'best_sell_through',
      summarize_result: false,
      // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
    },
  },
  {
    id: 'kings_report_underperforming_skus',
    name: 'Underperforming SKUs',
    description: 'remaining inventory > 2000, %ST < 3',
    queryKey: 'underperforming_skus',
    display: {
      // options: {
      //   columns: [
      //     "Root Cause", { name: "Frequency", align: "right" }, "Preventative Actions"
      //   ],
      // },
      transform: (data) => {
        return data.map((item) => {
          item.markdown = item.markdown.toFixed(2);
          return item;
        });
      }
    },
    apiParams: {
      use_case: 'kings_report',
      query_name: 'underperforming_skus',
      summarize_result: false,
      // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
    },
  },
  // {
  //   id: 'markdown_candidates',
  //   name: 'Markdown Candidates',
  //   description: '',
  //   queryKey: 'markdown_candidates',
  //   apiParams: {
  //     query_name: 'markdown_candidates',
  //     summarize_result: false,
  //     // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
  //   },
  // },
  // {
  //   id: 'over_ordered_skus',
  //   name: 'Cancel Over-Ordered SKUs',
  //   description: '',
  //   queryKey: 'over_ordered_skus',
  //   apiParams: {
  //     query_name: 'over_ordered_skus',
  //     summarize_result: false,
  //     // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
  //   },
  // },
  
];

const divisions = [
  { id: '1', name: '1' },
  { id: '1AA', name: '1AA' },
  { id: '2', name: '2' },
  { id: '2W', name: '2W' },
  { id: 'BOB', name: 'BOB' },
  { id: 'BOM', name: 'BOM' },
  { id: 'BOS', name: 'BOS' },
  { id: 'C', name: 'C' },
  { id: 'CAL', name: 'CAL' },
  { id: 'CCM', name: 'CCM' },
];

/**
 * A component that displays an image.
 *
 * @param {string} text - The source of the image to display.
 * @returns {JSX.Element} - A JSX element representing the image.
 */
const KingsReportSelection = (props) => {

  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [selectedDivisions, setSelectedDivisions] = useState([divisions[0].id]);
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [selectedReportSubType, setSelectedReportSubType] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const params = useParams();

  const toUtcDateBegin = (date) => {
    const YYYY = date.getFullYear();
    const MM = date.getMonth();
    const DD = date.getDate();
    return new Date(Date.UTC(YYYY, MM, DD, 0, 0, 0, 0));
  };

  const toUtcDateEnd = (date) => {
    const YYYY = date.getFullYear();
    const MM = date.getMonth();
    const DD = date.getDate();
    return new Date(Date.UTC(YYYY, MM, DD, 23, 59, 59, 999));
  };

  const handleDivisionClick = (e) => {
    const division = divisions.find(d => d.id === e.target.value);
    let updatedList = selectedDivisions;
    // switch to single selection for now
    // if (e.target.checked) {
    //   updatedList = [...updatedList, division.id];
    // } else {
    //   updatedList = updatedList.filter(d => d !== division.id);
    // }
    updatedList = [division.id];
    if (updatedList.length === 0) {
      updatedList = [divisions[0].id];
    }

    setSelectedDivisions(updatedList);
  };

  const submitQuery = () => {
    let report = selectedReportSubType || selectedReportType;

    // attach selected parameters
    if(report.apiParams) {
      report.apiParams.params = {
        start_date: toUtcDateBegin(dateFrom),
        end_date: toUtcDateEnd(dateTo),
        division: selectedDivisions[0],
        // include any preset parameters specified for the report
        ...report.presetParams 
      }
    }

    setModalOpen(false);

    const query = report.isAdHoc ? "" : `${report.name}, from ${new Date(dateFrom).toLocaleDateString()} to ${new Date(dateTo).toLocaleDateString()} for Division ${selectedDivisions.join(', ')}`;
    props.submitReportQuery(report.apiParams, query, !!report.isAdHoc, report.display);

  };

  const cancelQuery = () => {
    setModalOpen(false);
    if (!params.sessionId) {
      navigate('/text/usecases');
    }
  };

  useEffect(() => {
    setFormValid(dateFrom && dateTo && (dateFrom <= dateTo) && (selectedReportType?.queryKey || selectedReportSubType?.queryKey));
    console.log('form valid', !!formValid, dateFrom, dateTo, selectedReportType?.id, selectedReportSubType?.id);
  }, [selectedReportType, selectedReportSubType, dateFrom, dateTo, formValid, setFormValid]);

  return (
    <div>
      <Modal title="" modalOpen={modalOpen} useClose={false} classes={'use-case-params-modal'}>
        <div className="flex items-center justify-center bg-gray-100">
          <div className="bg-white rounded-xl container mx-auto">
            <div className="text-center text-3xl font-bold text-gray-700 mb-4">
              KINGS Report Analysis
            </div>
            <p className="text-gray-400 text-lg">
              <span className="font-bold text-xl text-gray-600">Purpose:</span> Use Skechers to analyze KINGS report data.
            </p>
            <p className="text-gray-400 text-lg mt-4">
              <span className="font-bold text-xl text-gray-600">Instructions:</span> Initiate your KINGS report analysis by selecting your desired time period and Division(s) along with the specific type of analysis you want to undertake. Click "Go" to begin.
            </p>

            <div className="border-t-2 border-gray-300 mt-4 mb-4"></div>

            <p className="text-gray-400 text-lg mt-4">
            <span className="font-bold text-xl text-gray-600">Scope of Analysis</span>
            </p>
            <p className="text-gray-400 text-lg mt-4">
            <span className="text-gray-600">Date Range</span>
            </p>
            <p className="flex flex-row text-gray-400 text-lg mt-1">
              <div className="flex flex-row mr-16"><DatePicker selected={dateFrom} onChange={date => setDateFrom(date)} className="outline outline-1 rounded py-1 px-2"/><MdCalendarMonth style={{ marginLeft: "-34px", marginTop: "4px", zIndex: 2, pointerEvents: "none"}} size="1.5em" /></div>
              <div className="flex flex-row"><DatePicker selected={dateTo} onChange={date => setDateTo(date)} className="outline outline-1 rounded py-1 px-2"/><MdCalendarMonth style={{ marginLeft: "-34px", marginTop: "4px", zIndex: 2, pointerEvents: "none"}} size="1.5em" /></div>
            </p>
            <p className="text-gray-400 text-lg mt-4">
              <span className="text-gray-600">Division(s)</span>
            </p>
            <div className="flex flex-row text-gray-400 text-lg mt-1">
              <div className="flex flex-row mr-16 flex-wrap">
                {divisions.map((division) => (
                  <div key={division.id} className="flex flex-row items-center space-x-2">
                    {/* <input type="checkbox" className="checkbox" checked={selectedDivisions.indexOf(division.id) >= 0} id={division.id} name={division.id} value={division.id} onClick={e => handleDivisionClick(e)}/> */}
                    <input type="radio" className="radio" checked={selectedDivisions.indexOf(division.id) >= 0} id={division.id} name={division.id} value={division.id} onClick={e => handleDivisionClick(e)}/>
                    <label htmlFor={division.id} className="px-2">{division.name}</label>
                  </div>
                ))}
              </div>
            </div>

            <p className="text-gray-600 text-lg font-bold mt-4">
              Analysis Type
            </p>
            <div className="flex justify-start pl-6 items-end space-x-6 flex-wrap">
              {reportTypes.map((reportType) => (
                <div key={reportType.id} className={`mt-3 inline-block hover:bg-blue-400 text-slate-800 rounded-md1 h-40 w-52 p-4 ${reportType.id === selectedReportType?.id ? "bg-blue-400" : "bg-gray-300"}`} onClick={() => {setSelectedReportType(reportType); setSelectedReportSubType(null);}}>
                  <div className="font-bold text-center">
                    {reportType.name}
                  </div>
                  <div className="mt-3 text-xs text-center">
                    {reportType.description}
                  </div>
                </div>
              ))}
            </div>

            <div className={selectedReportType?.subTypes ? "visible": "invisible"}>
              <p className="text-gray-600 text-lg font-bold mt-4">
                Analysis Sub-Type
              </p>
              <div className="flex justify-start pl-6 items-end space-x-2 flex-wrap">
                {selectedReportType?.subTypes?.map((reportType) => (
                  <div key={reportType.id} className={`mt-3 inline-block hover:bg-blue-400 text-slate-800 rounded-md1 h-40 w-36 p-1 ${reportType.id === selectedReportSubType?.id ? "bg-blue-400" : "bg-gray-300"}`} onClick={() => setSelectedReportSubType(reportType)}>
                    <div className="font-bold text-center">
                      {reportType.name}
                    </div>
                    <div className="mt-3 text-xs text-center">
                      {reportType.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-end items-end space-x-6">
              <button type="button" className="mt-4 inline-block bg-light-blue hover:bg-dark-blue text-white font-bold py-3 px-8 rounded-md" onClick={cancelQuery}>Cancel</button>
              <button type="button"disabled={!formValid} className="btn mt-4 inline-block bg-light-blue hover:bg-dark-blue text-white font-bold py-3 px-8 rounded-md" onClick={submitQuery}>Go</button>
            </div>
          </div>
        </div>
      </Modal>
      <button type="button" style={{ right: "8px", bottom: "148px" }} className="btn absolute inline-block bg-light-blue hover:bg-dark-blue text-white font-bold py-3 px-6 rounded-xl" onClick={() => setModalOpen(true)}><MdOutlineSettings size="1.5em" /></button>
    </div>
  )
};

export default KingsReportSelection;
export { reportTypes };

