import React, { useState, useContext, useEffect } from 'react'
import { FaFilter, FaBullhorn, FaCreativeCommonsNcJp } from "react-icons/fa"
import { IconContext } from 'react-icons/lib'
import Slider from "react-slider"
import avatar from '../../assets/avatar_robot.svg';
import { isArray } from 'chart.js/helpers';

const ProductKnowledgeBase = (props) => {

    const ragInfo = {
        dev: {
          "method": "kb",
          "identifier": "RX6WCG7IYT",
          "template": `Answer the following question.
          Use ONLY information found in these documents. If the necessary information is not found in these documents, say so.
          
          {context}
          
          Answer the following question and cite the sources used.
          
          Question: {prompt}
          `
        },
        sb: {
          "method": "kb",
          "identifier": "RX6WCG7IYT",
          "template": `Answer the following question.
          Use ONLY information found in these documents. If the necessary information is not found in these documents, say so.
          
          {context}
          
          Answer the following question and cite the sources used.
          
          Question: {prompt}
          `
        }
    };

    const reportTypes = {
        title: "Welcome to Skechers In-Store AI Assistant.",
        subTitle: "Select a question from the list below or type in your own question. Use the filter to further refine your search.",
        messages: [
            {
                "title": "Commonly Asked Questions",
                "example": "What are some shoes that offer good arch support for long hours of standing or walking?",
                "icon": null,
            },
            {
                "title": "Commonly Asked Questions",
                "example": "What are some options for a new pair of running shoes?",
                "icon": null,
            },
            {
                "title": "Commonly Asked Questions",
                "example": "Which are some options for moisture-wicking fitness apparel?",
                "icon": null,
            },
            {
                "title": "Goals and Updates",
                "example": "Learn more about Skechers 2025 Key Initiatives",
                "icon": <FaBullhorn />,
                subTypes: {
                    title: "Skechers 2025 Key Initiatives",
                    subTitle: "Click below to learn more.",
                    messages: [
                        {
                            "title": "Goals and Updates",
                            "example": "Which product line is capitalizing on the retro trend?",
                            // "prompt": "Which product line is capitalizing on the retro trend? Give a very concise answer with no commentary.",
                            "icon": null,
                            "meta_data_filter_config": {
                                "andAll": [
                                    {
                                        "equals": {
                                            "key": "retail_goals_updates",
                                            "value": "true"
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "title": "Goals and Updates",
                            "example": "Glide-Step technology is exlusively available with which Skechers feature?",
                            // "prompt": "Glide-Step technology is exlusively available with which Skechers feature?  Give a very concise answer with no commentary.",
                            "icon": null,
                            "meta_data_filter_config": {
                                "andAll": [
                                    {
                                        "equals": {
                                            "key": "retail_goals_updates",
                                            "value": "true"
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "title": "Goals and Updates",
                            "example": "What unique lacing system does Cozy Fit feature?",
                            // "prompt": "What unique lacing system does Cozy Fit feature?  Give a very concise answer with no commentary.",
                            "icon": null,
                            "meta_data_filter_config": {
                                "andAll": [
                                    {
                                        "equals": {
                                            "key": "retail_goals_updates",
                                            "value": "true"
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "title": "Goals and Updates",
                            "example": "Which comfort technologies are available in the new sandal line?",
                            // "prompt": "Which comfort technologies are available in the new sandal line?  Give a very concise answer with no commentary.",
                            "icon": null,
                            "meta_data_filter_config": {
                                "andAll": [
                                    {
                                        "equals": {
                                            "key": "retail_goals_updates",
                                            "value": "true"
                                        }
                                    }
                                ]
                            }
                        },
                    ]
                }
            },
        ]
    }

    const [filterOpen, setFilterOpen] = useState(false)
    const [question, setQuestion] = useState(null);
    const [panelOpen, setPanelOpen] = useState(true);
    const [reportScope, setReportScope] = useState(reportTypes);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (question) {
            props.submitPrompt(null, question);
            console.log('Prompt: ', question);
            setPanelOpen(false);
        }
    }, [question]);

    useEffect(() => {
        if (message) {
            let ragConfig = ragInfo[process.env.REACT_APP_ENVIRONMENT];
            if (message.meta_data_filter_config) {
                ragConfig.meta_data_filter_config = message.meta_data_filter_config;
            }
            console.log(ragConfig)
            props.setRagInfo(ragConfig);

            if (message.prompt || message.example) {
                setQuestion(message.prompt || message.example);
            }
        }
    }, [message]);

    const iconStyle = useContext(IconContext)

    const toggleFilter = () => {
        setFilterOpen(!filterOpen)
    }

    return (

        <div className='flex flex-col bg-white xxitems-center xxjustify-center grow absolute top-0 left-0 right-0 z-10'>
            <div className="w-full overflow-y-auto h-full overflow-x-hidden">
                <div className="pk-header">
                    <div className="pk-header-topics-text cursor-pointer">
                        <span onClick={() => { setReportScope(reportTypes); setPanelOpen(!panelOpen)}}>All Topics </span>
                        <span className={reportScope.title === "Welcome to Skechers In-Store AI Assistant." ? "hidden" : ""} onClick={() => setPanelOpen(!panelOpen)}>/ 2025 Key Initiatives</span>
                    </div>
                    <div className={
                        filterOpen === true
                            ? "pk-header-filter-button-open"
                            : "pk-header-filter-button-closed"
                    }
                        onClick={() => {toggleFilter(); setPanelOpen(true)}}
                    >
                        <IconContext.Provider value={{
                            color: filterOpen === true ? "white" : "#0284c7",
                            size: "20px"
                        }}>
                            <FaFilter />
                        </IconContext.Provider>
                        <div className={
                            filterOpen === true
                                ? "pk-header-filter-text-open"
                                : "pk-header-filter-text-closed"
                        }>
                            Filter
                        </div>
                    </div>
                </div>
                <div className={`pk-main ${panelOpen ? "" : "hidden"}`}>
                    <div className="pk-content">
                        <div className="pk-message-area">
                            <div className={""}>
                                <div style={{ padding: "2em" }} className="bg-white rounded-xl container mx-auto">
                                    <div className={""} style={{ marginTop: 40, marginBottom: 40 }}>
                                        <div style={{ float: "left", marginRight: 30 }} >
                                            <img src={avatar} alt="AI Assisstant Avatar Icon" />
                                        </div>
                                        <p className="text-gray-400 text-lg">
                                            <span className="font-bold text-xl text-gray-600">{reportScope.title}</span>
                                        </p>
                                        <p className="text-gray-400 text-lg mt-4">
                                            {reportScope.subTitle}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {reportScope.messages.map((message, index) => (
                                <div onClick={() => message.subTypes ? setReportScope(message.subTypes) : message.meta_data_filter_config ? setMessage(message) : setQuestion(message.prompt || message.example)} key={index} className="pk-message cursor-pointer hover:bg-blue-50 transition-colors duration-200">
                                    {message.icon === null
                                        ? null
                                        : <div className="pk-message-icon">
                                            <IconContext.Provider value={{ color: "#0284c7", size: "20px" }}>
                                                {message.icon}
                                            </IconContext.Provider>
                                        </div>
                                    }
                                    <div className="pk-message-contents">
                                        <div className="pk-message-title">
                                            {message.title}
                                        </div>
                                        <div className="pk-message-text">
                                            {message.example}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <ChatArea /> */}
                    </div>
                    <FilterContainer filterOpen={filterOpen} setFilterOpen={setFilterOpen} ragInfo={ragInfo} setRagInfo={props.setRagInfo}/>
                </div>
            </div>
        </div>
    )
}

const FilterContainer = ({ filterOpen, setFilterOpen, ragInfo, setRagInfo }) => {
    const sliderMin = 0
    const sliderMax = 150

    const [sliderValues, setSliderValues] = useState([sliderMin, sliderMax])

    const sliderMaxLabel = (sliderValues[1] === sliderMax)
        ? `${sliderMax}+`
        : `${sliderValues[1]}`

    const [color, setColor] = useState("")

    const [checkboxFilters, setCheckboxFilters] = useState({})

    // update filters when color or checkboxFilters change
    useEffect(() => {
        console.log('color or checkboxFilters changed, set filters');
        applyFilters();
    }, [color, checkboxFilters])

    const colors = [
        { label: "Black", value: ["BLK", "BBK"] },
        { label: "White", value: "WHT" },
        { label: "Navy", value: "NVY" },
        { label: "Taupe", value: "TPE" },
        { label: "Gray", value: "GRY" },
        { label: "Black/White", value: "BKW" },
        { label: "Brown", value: "BRN" },
        { label: "Pink", value: "PNK" },
        { label: "Charcoal", value: "CHAR" },
        { label: "Blue", value: "BLU" },
    ]

    /*
    "BLK" - black
    "BBK" - black
    "WHT" - white
    "NVY" - navy
    "TPE" - taupe
    "GRY" - gray
    "BKW" - black/white
    "BRN" - brown
    "PNK" - pink
    "CHAR" - charcoal
    "BLU" - blue
    */

    const checkBoxes = [
        {
            id: 'productType',
            name: 'PRODUCT TYPE',
            description: '',
            meta_data_filter_config:  { "in_condition": { "key": "productType", "value": [] } },
            values: [
                {
                    id: 'FOOTWEAR',
                    text: "Footwear"
                },
                {
                    id: 'APPAREL',
                    text: "Clothing"
                },
                {
                    id: 'ACCESSORIES',
                    text: "Accessories"
                }
            ],
        },
        {
            id: 'customerType',
            name: 'CUSTOMER TYPE',
            description: '',
            meta_data_filter_config:  { "in_condition": { "key": "customerType", "value": [] } },
            values: [
                {
                    id: 'MENS',
                    text: "Men"
                },
                {
                    id: 'WOMENS',
                    text: "Women"
                },
                {
                    id: 'GIRLS',
                    text: "Girls"
                },
                {
                    id: 'BOYS',
                    text: "Boys"
                }
            ],
        },
        {
            id: 'activity',
            name: 'ACTIVITY',
            description: '',
            meta_data_filter_config:  { "in_condition": { "key": "activity", "value": [] } },
            // "No Sport", "Walking", "Running", "Training", "Golf", "Trail", "Pickleball", "Soccer", "Basketball", "Cricket", "Court", "Football"
            values: [
                {
                    id: "No Sport",
                    text: "No Sport"
                },
                {
                    id: "Walking",
                    text: "Walking"
                },
                {
                    id: "Running",
                    text: "Running"
                },
                {
                    id: "Training",
                    text: "Training"
                },
                {
                    id: "Golf",
                    text: "Golf"
                },
                {
                    id: "Trail",
                    text: "Trail"
                },
                {
                    id: "Pickleball",
                    text: "Pickleball"
                },
                {
                    id: "Soccer",
                    text: "Soccer"
                },
                {
                    id: "Basketball",
                    text: "Basketball"
                },
                {
                    id: "Cricket",
                    text: "Cricket"
                },
                {
                    id: "Court",
                    text: "Court"
                },
                {
                    id: "Football",
                    text: "Football"
                },
            ],
        }
    ]

    const checkedCount = checkBoxes.reduce(
        (checks, type) => {
            const checkedVals = type.values.filter(
                (val) => val.selected === true
            )
            if (checkedVals.count > 0) {
                checks.push(checkedVals)
            }
            return checks
        },
        []
    )

    const applyDisabled = (
        // sliderValues[0] === sliderMin
        // && sliderValues[1] === sliderMax
        // && color === ""
        // && checkedCount === 0
        false
    )

    const handleCheck = (id, value) => {
        let currentVal = checkboxFilters[id] || [];
        if (currentVal.includes(value)) {
            currentVal = currentVal.filter((val) => val !== value)
        } else {
            currentVal.push(value)
        }

        // console.log(id, currentVal);

        setCheckboxFilters(
            {
                ...checkboxFilters,
                [id]: currentVal
            }
        )
    }

    const applyFilters = () => {

        // example:
        // const filters = {
        //     "meta_data_filter_config": { 
        //         "andAll": [
        //             { "in_condition": { "key": "productType", "value": ["FOOTWEAR"] } }, 
        //             { "in_condition": { "key": "customerType", "value": ["WOMENS"] } }, 
        //             { "in_condition": { "key": "color", "value": ["NVY"] } }, 
        //             { "in_condition": { "key": "activity", "value": ["Golf"] } }
        //         ] 
        //     }
        // }

        const andAllList = [];
        checkBoxes.forEach((filter) => {
            if (checkboxFilters[filter.id] && checkboxFilters[filter.id].length > 0) {
                const filterData = {...filter.meta_data_filter_config}
                filterData.in_condition.value = checkboxFilters[filter.id];
                andAllList.push(filterData);
            }
        });

        if (color) {
            andAllList.push({ "in_condition": { "key": "color", "value": isArray(color) ? color : [color] } });
        }

        const filters = {
            "meta_data_filter_config": {
                "andAll": andAllList
            }
        };

        // set correct KB based on environment
        let ragConfig = ragInfo[process.env.REACT_APP_ENVIRONMENT];
        ragConfig.meta_data_filter_config = filters.meta_data_filter_config;
        console.log(ragConfig)
        setRagInfo(ragConfig);
    }

    const CheckList = (data) => {
        // const [checks, setChecks] = useState({})
        const { name, id, values } = data.data
        return (
            <div className="pk-filter-checkbox">
                <div className="pk-filter-checkbox-title">
                    {name}
                </div>
                <div>
                    {values.map(
                        (value, index) => {
                            return (
                                <div
                                    className="pk-filter-checkbox-item"
                                    key={index}
                                >
                                    <div className="flex items-center">
                                        <input type="checkbox" onChange={() => handleCheck(id, value.id)} checked={(checkboxFilters[id] || []).includes(value.id)} />
                                        <div className="pk-filter-checkbox-item-text">
                                            {value.text}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className={`pk-filter-params relative flex shrink flex-col overflow-y-auto transition-all ease-in-out duration-300
          ${filterOpen ? 'translate-x-0 opacity-100 w-72' : 'translate-x-full opacity-0 w-0'}`}>
            {/* Content that slides in/out */}
            <div className="">
                <div className="pk-filter-head">
                    <div className="font-bold">
                        Filter
                    </div>
                    <div className="text-sky-600 cursor-pointer" onClick={() => setFilterOpen(!filterOpen)}>
                        X
                    </div>
                </div>
                <div className="pk-filter-options">
                    {
                        checkBoxes.map(
                            (boxInfo) => {
                                return <CheckList key={boxInfo.id} data={boxInfo} />
                            })
                    }
                    <div className="pk-slider-area">
                        <div className="pk-slider-area-title">Price</div>
                        <div className="pk-slider-area-values">${sliderValues[0]} – ${sliderMaxLabel}</div>

                        <Slider
                            className="pk-slider"
                            onChange={setSliderValues}
                            value={sliderValues}
                            min={sliderMin}
                            max={sliderMax}
                        />
                    </div>
                    <div className="pk-color-area">
                        <div className="pk-color-area-title">Color</div>

                        <select className="pk-color-select" value={color} onChange={(event) => setColor(event.target.value)}>
                            <option value="">Select color(s)</option>
                            {colors.map(
                                (color) => {
                                    return (
                                        <option key={color.value} value={color.value}>
                                            {color.label}
                                        </option>
                                    )
                                }
                            )}
                        </select>
                    </div>
                    {/* <div className="pk-apply-area" style={{ border: "0px" }}>
                        <button className="pk-filters-apply" disabled={applyDisabled} onClick={applyFilters}>
                            Apply Filters
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ProductKnowledgeBase
